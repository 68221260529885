import mixpanel from "mixpanel-browser";
import { Document } from "../api";

export function isMobile() {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    // You can adjust the breakpoint according to your needs
    const mobileBreakpoint = 768;

    return screenWidth < mobileBreakpoint;
}

export function recordEvent(eventName: string, eventProperties: any) {
    mixpanel.track(eventName, eventProperties);
}

export type DateOptions = {
    year: "numeric" | "2-digit" | undefined,
    month: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined,
    day: "numeric" | "2-digit" | undefined;
} | undefined;

export function getDate(doc: Document, options: DateOptions = undefined) {
    let date = doc.decision_date || doc.date_of_decision || doc.date;
    if (!date) {
        return "";
    }

    return new Date(date).toLocaleDateString("en-AU", options);
}

