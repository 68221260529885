import styles from "./Example.module.css";
import { Typography } from "@mui/joy";

interface Props {
    text: string;
    value: string;
    onClick: (value: string) => void;
}

export const Example = ({ text, value, onClick }: Props) => {
    return (
        <div className={styles.example} onClick={() => onClick(value)}>
            <Typography level="body-sm" className={styles.exampleText}>{text}</Typography>
        </div>
    );
};
