import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { getUser } from "../api";
import mixpanel from "mixpanel-browser";
import Cookies from 'js-cookie';
import { CountryContext } from "./CountryContext";

interface Props {
    children: React.ReactNode;
}

type Plan = {
    created: string;
    id: number;
    name: string;
    searches: number;
    stripe_id: string;
    updated: string;
    subscription_expiration: string | null;
    subscription_start: string;
};

type User = {
    email: string;
    first: string;
    id: string;
    last: string;
    plan: Plan;
    searches_left: number;
    reset_date: string;
    referral_code: string;
    referrals: any[];
};

export type UserContextType = {
    user: User | null;
    updateUser: () => void;
};

export const UserContext = React.createContext<UserContextType>({
    user: null,
    updateUser: () => {
    }
});
export const UserProvider: React.FC<Props> = ({ children }) => {
    const { getToken } = useAuth();
    const { country } = useContext(CountryContext);

    const [userDetails, setUserDetails] = useState<User | null>(null);
    const updateUser = async () => {
        const inviteCode = Cookies.get('invite_code');
        const resp = await getUser(await getToken({ template: "Standard" }), inviteCode, country.name);
        const parsedResp = await resp.json();
        setUserDetails(parsedResp);
        mixpanel.identify(parsedResp.id);
    };

useEffect(() => {
    if (country !== null) {
        updateUser();
    }
}, [country]); // Add country as a dependency

    return (
        <UserContext.Provider value={{ user: userDetails, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};