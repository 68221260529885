import React, { useContext, useEffect } from "react";
import { useAuth, SignInButton, UserButton, useUser } from "@clerk/clerk-react";
import { Box, Button } from "@mui/joy";
import { UserContext } from "../../contexts/UserContext";
import Cookies from 'js-cookie';

const DotIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="currentColor"
        >
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
        </svg>
    );
};
export const Authentication = () => {
    const { isSignedIn } = useAuth();
    const { user } = useContext(UserContext);
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        const inviteCode = queryParams.get('invite_code');
        if (inviteCode) {
            Cookies.set('invite_code', inviteCode, {expires: 2});
        }
    }, []);

    return isSignedIn  ? <UserButton> <UserButton.UserProfilePage
            label="Subscription"
            url="custom"
            labelIcon={<DotIcon />}
        >
            <Box>
                <Button component="a"
                        href={ import.meta.env.DEV ?`https://billing.stripe.com/p/login/test_eVa7tj16Kg1e5LW6oo?prefilled_email=${encodeURIComponent(user?.email||"")}` : `https://billing.stripe.com/p/login/3csg13blV6a3eswcMM?prefilled_email${encodeURIComponent(user?.email||"")}` }>Go
                    to payments
                    page</Button>
            </Box>
        </UserButton.UserProfilePage>
        </UserButton> :
        <SignInButton mode={"modal"}><Button variant={"soft"}>Sign In</Button></SignInButton>;
};