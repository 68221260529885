import React, { useState } from "react";
import { IconButton } from "@fluentui/react";
import { Alert, Box, Typography } from "@mui/joy";

export const CopyButton = ({ textToCopy }: { textToCopy: string }) => {
    const [success, setSuccess] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                // Handle successful copying here, like showing a notification
                console.log("Text copied to clipboard");
                setSuccess(true);
                setTimeout(() => setSuccess(false), 1000); // Hide the alert after 2 seconds
            })
            .catch(err => {
                // Handle errors here
                console.error("Failed to copy text: ", err);
            });
    };

    return (
        <Box sx={{"display":"flex", direction:"row", position:"relative"}} >
            {success && <Alert sx={{zIndex:9000, position:"absolute", right:"-60px",}} size={"sm"} color={"success"}>Copied</Alert>}
            <IconButton
                style={{ color: "black" }}
                iconProps={{ iconName: "Copy" }}
                title="Copy to clipboard"
                ariaLabel="Copy to clipboard"
                onClick={copyToClipboard} />
        </Box>
    );
};