import React, { useEffect, useRef, useState } from "react";
import {
    Badge,
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemContent,
    ListSubheader,
    Sheet,
    Tooltip,
    Typography
} from "@mui/joy";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import { listConversations} from "../../api";
import styles from "./Sidebar.module.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/logo_b.png";
import { useAuth } from "@clerk/clerk-react";
import { UserMenu } from "../UserMenu";
import { format, isToday, isYesterday } from "date-fns";
import { SubscriptionDetail } from "../SubscriptionDetail";
import { Add } from "@mui/icons-material";
import RestoreIcon from "@mui/icons-material/Restore";
import { isMobile } from "../../util/utils";

interface Conversation {
    created: string;
    id: number;
    messages: Message[];
    saved: boolean;
    updated: string;
}

interface Message {
    answer: string;
    conversation: Conversation;
    created: string;
    id: number;
    question: string;
    updated: string;
    user: string;
}

export const Sidebar = ({ onClose, sidebarOpen }: { onClose?: () => void, sidebarOpen: boolean }) => {
    const { isSignedIn, getToken } = useAuth();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const boxRef = useRef<HTMLElement | null>(null);
    const [conversations, setConversations] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const getConversations = async (page: number) => {
        if (!hasMoreItems) {
            return;
        }
        setLoading(true);
        const resp = await listConversations(await getToken({ template: "Standard" }), page);
        const data = await resp.json();

        if (data.length === 0) {
            setHasMoreItems(false);
            setLoading(false);
            return;
        }

        const newConversations = data.filter((newConvo: any) =>
            !conversations.some((existingConvo: any) => existingConvo.id === newConvo.id)
        );

        setConversations(prevConversations => [...prevConversations, ...newConversations]);
        setLoading(false);
    };

    const setNewChat = () => {
        searchParams.delete("conversationId");
        setSearchParams(searchParams);
        getConversations(1);
        navigate("/");
        onClose && onClose();
    };

    useEffect(() => {
        if (isSignedIn) {
            getConversations(page);
        }
    }, [isSignedIn, page]);


    useEffect(() => {
        const handleScroll = () => {
            const box = boxRef.current;
            if (box && box.scrollHeight - box.scrollTop === box.clientHeight && !loading && hasMoreItems) {
                setPage(prevPage => prevPage + 1);
            }
        };
        const box = boxRef.current;
        if (box) {
            box.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (box) {
                box.removeEventListener("scroll", handleScroll);
            }
        };
    }, [loading, hasMoreItems]);

    const groupedConversations = conversations.sort((a, b) => {
        if (a.created && b.created) {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
        } else {
            return 0;
        }
    }).reduce((groups, convo) => {
        if (convo.created) {
            let dateObject = new Date(convo.created);
            dateObject = new Date(dateObject.toLocaleString("en-US", { timeZone: "Australia/Sydney" }));
            let formattedDate;
            if (isToday(dateObject)) {
                formattedDate = "Today";
            } else if (isYesterday(dateObject)) {
                formattedDate = "Yesterday";
            } else {
                formattedDate = format(dateObject, "MMMM dd, yyyy");
            }
            if (!groups[formattedDate]) {
                groups[formattedDate] = [];
            }
            groups[formattedDate].push(convo);
        }
        return groups;
    }, {});

    const handleClickAway = () => {
        if (isMobile() && onClose) {
            onClose();
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
                <Sheet sx={{
                    position: { xs: "fixed", md: "sticky" },
                    transform: {
                        md: "none"
                    },
                    transition: "transform 0.4s, width 0.4s",
                    height: "100dvh",
                    maxHeight: "100vh",
                    width: "var(--Sidebar-width)",
                    top: 0,
                    p: 2,
                    zIndex: 999,
                    flexShrink: 0,
                    boxSizing: "border-box",
                    display: sidebarOpen ? "flex" : "none",
                    flexDirection: "column",
                    gap: isMobile() ? 1 : 3,
                    borderRight: "1px solid",
                    borderColor: "divider",
                    overflow: "hidden",
                    maxWidth: "10dvw",
                    minWidth: "220px"
                }}>
                    {!isMobile() && (
                        <Box className={styles.logoArea}>
                            <Link to={"/"} onClick={() => setNewChat()} className={styles.headerTitleContainer}>
                                <img className={styles.headerLogo} src={logo} />
                            </Link>
                        </Box>
                    )}

                    <List sx={{ overflow: "hidden" }} size="sm">
                        <ListItem>
                            <ListItemButton className={styles.largeListItemButton} onClick={() => setNewChat()}>
                                <QuestionAnswerRoundedIcon />
                                <ListItemContent>
                                    <Typography level="title-sm">New chat</Typography>
                                </ListItemContent>
                                <Add />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>

                            <ListItemButton className={styles.largeListItemButton}
                                            onClick={() => navigate("/casenotes")}>

                                <ArticleRoundedIcon />
                                <ListItemContent>
                                    <Box sx={{ display: "flex", direction: "column", gap: 2 }}>
                                        <Typography level="title-sm">Case notes</Typography>
                                        <Badge anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right"
                                        }}
                                               size={"sm"}
                                               badgeContent={"new"}
                                        /></Box>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ height: "100%" }} nested>
                            <ListItem sx={{ mt: isMobile() ? 0 : 3 }} className={styles.largeListItemButton}>
                                <RestoreIcon />
                                <Typography level="title-sm">History</Typography>
                            </ListItem>
                            <Divider />
                            <Box
                                className={`${styles.chatHistory} ${styles.chatHistoryScrollbar}`} /* Apply the custom scrollbar class here */
                                sx={{
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    height: "100%"
                                }} // Flexibly fills available space
                                ref={boxRef}
                            >
                                <List>
                                    {Object.keys(groupedConversations).map((date: string) => (
                                        <ListItem nested key={date}>
                                            <ListSubheader sticky>{date}</ListSubheader>
                                            <List>
                                                {groupedConversations[date].map((convo: Conversation) => (
                                                    <ListItem sx={{ mt: .25 }}>
                                                        {convo.messages[0]?.question && (
                                                            <ListItem sx={{ width: "100%" }}
                                                                      className={styles.historyItem}>
                                                                <Tooltip title={convo.messages[0]?.question}
                                                                         placement="right">
                                                                    <ListItemButton
                                                                        onClick={() => navigate(`/?conversationId=${convo.id}`)}>
                                                                        <Typography
                                                                            noWrap={true}
                                                                            level="body-sm">{convo.messages[0]?.question} </Typography>
                                                                    </ListItemButton>
                                                                </Tooltip>
                                                            </ListItem>
                                                        )}
                                                    </ListItem>
                                                ))}</List>

                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </ListItem>
                    </List>
                    <Divider />
                    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 0, gap: isMobile() ? 1 : 3 }}>
                        {
                            isSignedIn &&
                            <SubscriptionDetail />
                        }
                        <UserMenu />
                    </Box>

                </Sheet>
        </ClickAwayListener>
    );
};