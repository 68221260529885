import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import { ChatAppResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { CopyButton } from "../CopyButton";
import { isMobile } from "../../util/utils";
import { Box, Button, Sheet, Typography } from "@mui/joy";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { FrequentlyCitedLabel } from "../FrequentlyCitedLabel";

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    triggerMoreResults?: () => void;
    chatType: string;
}


export const removeDuplicates = (arrayWithDuplicates: any[], keyToFilter: string) => {
    const uniqueIds = new Set();
    const uniqueArray = arrayWithDuplicates.filter(item => {
        if (!uniqueIds.has(item[keyToFilter])) {
            uniqueIds.add(item[keyToFilter]);
            return true;
        }
        return false;
    });
    return uniqueArray;
};

const removeLabels = (text:string) => {
    const labels = ["[frequently_cited]"];
    labels.forEach(label => {
        text = text.replace(label, "");
    });
    return text
}

export const Answer = ({
                           answer,
                           isSelected,
                           isStreaming,
                           onCitationClicked,
                           onSupportingContentClicked,
                           onFollowupQuestionClicked,
                           showFollowupQuestions,
                           triggerMoreResults,
                           chatType
                       }: Props) => {
    const followupQuestions = answer.choices[0]?.context?.followup_questions;
    const messageContent = removeLabels(answer.choices[0].message.content);
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked), [answer]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    return (
        <Sheet className={`${styles.answerContainer} ${isSelected && styles.selected}`}>

            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <div />
                    <Box sx={{display:"flex", direction:"row"}}>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.choices[0].context.data_points?.length && !answer.documents?.length}
                        />
                        <CopyButton textToCopy={messageContent} />
                    </Box>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}
                               className={styles.answerText}>{sanitizedAnswerHtml}</ReactMarkdown></Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <>
                                    {isMobile() ? (
                                        <><a key={i} className={styles.citation} title={x}
                                             href={answer.documents[i].url} target="_blank"
                                             rel="noopener noreferrer"
                                        >
                                            {`${++i}. ${removeLabels(x)}`} </a> {x.includes("[frequently_cited]") &&
                                            <div className={styles.frequentlyCitedLabels}>
                                                <FrequentlyCitedLabel />
                                            </div>}</>) : (<><a key={i} className={styles.citation}
                                                                title={x}
                                                                onClick={() => onCitationClicked(path)}
                                        >
                                            {`${++i}. ${removeLabels(x)}`}
                                        </a>{x.includes("[frequently_cited]") &&
                                            <div className={styles.frequentlyCitedLabels}>
                                                <FrequentlyCitedLabel />
                                            </div>}</>
                                    )
                                    }
                                </>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!answer.documents?.length && !parsedAnswer.citations?.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Documents referenced:</span>
                        {removeDuplicates(answer.documents.slice(0, 7), "name").map((x, i) => {
                            const path = x.url;
                            return (
                                <>
                                    {isMobile() ? (
                                        <a key={i} className={styles.citation} title={x}
                                           href={path} target="_blank"
                                           rel="noopener noreferrer"
                                        >
                                            {`${++i}. ${x.name}`}
                                        </a>) : (<a key={i} className={styles.citation} title={x}
                                                    onClick={() => onCitationClicked(path)}
                                    >
                                        {`${++i}. ${x.name}`}
                                    </a>)
                                    }
                                </>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && chatType == "chat" && (
                <Stack.Item>
                    <Stack horizontal wrap
                           className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
                           tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x}
                                   onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {chatType == "chat" && <Box sx={{ display: "flex", flexDirection: "row-reverse", paddingTop: "1vh" }}>
                <Button size="sm" startDecorator={<LowPriorityIcon />} color="primary" variant={"plain"}
                        onClick={triggerMoreResults}>Try with more
                    results</Button>
            </Box>}
                <Typography className={styles.disclaimer} level="body-xs">This is not legal advice.</Typography>

        </Sheet>
    );
};
