import { Typography } from "@mui/joy";
import styles from "./SupportingContent.module.css";
import { Box, Button } from "@mui/joy";
import { getDate } from "../../util/utils";
import { CaseNoteViewer } from "../CaseNoteViewer";
import { Launch } from "@mui/icons-material";
import { FrequentlyCitedLabel } from "../FrequentlyCitedLabel";

interface Props {
    supportingContent: string[];
    documents: any[];
}


function removeDuplicates(arr: any[], key: string) {
    const seen = new Set();

    return arr.filter((item: any) => {
        const value = item[key];
        if (seen.has(value)) {
            return false;
        }
        seen.add(value);
        return true;
    });
}


export const SupportingContent = ({ supportingContent, documents }: Props) => {
    documents = removeDuplicates(documents, "url");
    return (
        <ul className={styles.supportingContentNavList}>
            {documents.map((x, i) => {
                return (
                    <li className={styles.supportingContentItem}>
                        <Box>
                            <Typography level="title-sm">{documents[i].name.replace("[frequently_cited]","")}</Typography>
                            <Typography
                                level="body-sm">{getDate(documents[i])} {documents[i].category ? "| " + documents[i].category?.split(" ")[0] : null} {documents[i].before ? "| Judgement of " + documents[i].before : null}</Typography>
                            {documents[i].most_cited && <FrequentlyCitedLabel />}
                        </Box>
                        <Typography level="body-sm" variant="soft">{documents[i].extract}</Typography>

                        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                        <Button startDecorator={<Launch />} variant={"plain"} size={"md"} component="a" href={documents[i].url} target="_blank"
                                sx={{ textDecoration: "none" }} // Add this line
                                rel="noopener noreferrer">Original </Button>
                            {documents[i].summary1 &&( <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                            <CaseNoteViewer summary={JSON.parse(documents[i].summary1)}/>
                        </Box>)}
                        </Box>
                    </li>
                );
            })}
        </ul>
    );
};
