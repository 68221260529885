import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom"; // Changed from createHashRouter to createBrowserRouter
import { initializeIcons } from "@fluentui/react";
import "./index.css";
import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import { ClerkProvider } from "@clerk/clerk-react";
import { ThemeProvider, extendTheme } from "@mui/joy";
import { UserProvider } from "./contexts/UserContext";
import { isMobile } from "./util/utils";
import mixpanel from "mixpanel-browser";
import Hotjar from "@hotjar/browser";
import Feed from "./pages/feed/Feed";
import { CountryProvider } from "./contexts/CountryContext";

const siteId = 3741740;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

mixpanel.init("6cdb7f6e2e72e0a8b19c647a4771e450", { debug: true, track_pageview: true, persistence: "localStorage" });

var layout;

layout = <Layout />;

initializeIcons();

const router = createBrowserRouter([ // Changed to createBrowserRouter
    {
        path: "/",
        element: layout,
        children: [
            {
                index: true,
                element: <Chat />
            },
            {
                path: ":countryCode",
                element: <Chat />
            },
            {
                path: "/casenotes",
                children: [
                    {
                        index: true,
                        element: <Feed />
                    },
                    {
                        path: "*",
                        lazy: () => import("./pages/NoPage")
                    }
                ]
            }, {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }

]);
declare global {
    interface Window {
        $sleek: [];
        SLEEK_PRODUCT_ID: number;
    }
}

mixpanel.init("YOUR_MIXPANEL_TOKEN");

const App = () => {
    if (!isMobile()) {
        window["$sleek"] = [];
        window["SLEEK_PRODUCT_ID"] = 38395668;
        (function() {
            let d = document;
            let s = d.createElement("script");
            s.src = "https://client.sleekplan.com/sdk/e.js";
            s.async = true;
            d.getElementsByTagName("head")[0].appendChild(s);
        })();
    }
    return (
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
};

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

let VITE_CLERK_PUBLISHABLE_KEY = import.meta.env.DEV ? "pk_test_bm9ibGUtY3JheWZpc2gtMzYuY2xlcmsuYWNjb3VudHMuZGV2JA" : "pk_live_Y2xlcmsuY291cnRhaWQuYWkk";
// Create a theme instance.
const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    solidBg: "#264677",  // Solid background color for buttons, etc
                    plainColor: "#264677",
                    solidBorder: "#264677",  // Text color,
                    solidActiveBg: "#264677",
                    outlinedColor: "#264677",
                    plainActiveColor: "#264677"  // Background color for active buttons, etc

                    // Background color for outlined buttons, etc

                    // Background color for active buttons, etc
                }

            }
        }
    }
});
ReactDOM.createRoot(rootElement).render(
    <ClerkProvider
        publishableKey={VITE_CLERK_PUBLISHABLE_KEY}
        appearance={{
            layout: {
                termsPageUrl: "https://courtaid.ai/terms-and-conditions/",
                privacyPageUrl: "https://courtaid.ai/privacy-policy/"
            }
        }}
    >
        <ThemeProvider theme={theme}>
            <CountryProvider>
                <UserProvider>
                    <App />
                </UserProvider>
            </CountryProvider>
        </ThemeProvider>
    </ClerkProvider>
);

