import React, { useEffect, useState } from "react";
import { Stack, Card, Typography, Box, Chip, Divider, Skeleton, Button } from "@mui/joy";
import styles from "./Feed.module.css";
import { CaseNoteViewer } from "../../components/CaseNoteViewer";
import { CaseNotesSearchRequest, Document, FilterRequest, getCaseNotes } from "../../api";
import { useAuth } from "@clerk/clerk-react";
import { Filters } from "../../components/Filters";
import { Launch } from "@mui/icons-material";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import { isMobile } from "../../util/utils";

const BetaCard = () => (
    <Card variant={"outlined"} color={"primary"}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <SmartToyOutlinedIcon />
            <Typography color={"primary"} level={"h4"}>This feature is in Beta</Typography>
        </Box>
        <Divider />
        <Typography level={"body-sm"}>This feature is still in development. Currently, it covers judgments from 2022,
            but soon will expand to cover the entire dataset after testing is complete. Please leave any feedback at the
            feedback bot.
        </Typography>
    </Card>
);
const Result = ({ row }: { row: Document }) => {
    return (
        <Card size={"lg"} className={styles.ResultCard}>
            <div><Typography level={"body-lg"}>{row.name}</Typography></div>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                {row.categories && row.categories.map((category) => (
                    <Chip size={"sm"} variant={"solid"}>{category}</Chip>
                ))}
            </Box>
            <Divider />
            <Box sx={{ display: "flex" }}>
                {row.date && <Typography level={"body-xs"}>{row.date}</Typography>}
                {row.blurb && <Typography level={"body-xs"}>{row.blurb}</Typography>}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>{
                row.summary1 &&
                <CaseNoteViewer summary={JSON.parse(row.summary1)} />}
                <Button startDecorator={<Launch />} size={"md"} variant={"plain"} component="a" href={row.url}
                        target="_blank"
                        rel="noopener noreferrer"> Original </Button>
            </Box>
        </Card>
    );
};


const SkeletonResult = () => (
    <Card size={"lg"} className={styles.ResultCard}>
        <Skeleton animation="wave" variant="text" />
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            <Skeleton animation="wave" variant="text" width={60} />
            <Skeleton animation="wave" variant="text" width={120} />
            <Skeleton animation="wave" variant="text" width={60} />
            <Skeleton animation="wave" variant="text" width={60} />
        </Box>
        <Divider />
        <Skeleton animation="wave" variant="text" />
        <Skeleton animation="wave" variant="text" />
        <Skeleton animation="wave" variant="text" />
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Button
                variant="solid"
                size="sm"
            >
                Explore
                <Skeleton animation="wave" />
            </Button>
        </Box>
    </Card>
);

type Row = {
    name: string;
    categories: string[];
    date: string;
    blurb: string;
    summary1: string;
    url: string;
};

const Feed = () => {
    const { isSignedIn, getToken } = useAuth();
    const [filters, setFilters] = useState<FilterRequest>({});
    const [data, setData] = useState<Document[]>([]); // State to store the data
    const [search, setSearch] = useState<string>(""); // State to store the search query
    const [isFetching, setIsFetching] = useState<boolean>(false); // State to store the fetching status
    const [page, setPage] = useState<number>(1); // State to store the current page number
    const [hasMoreData, setHasMoreData] = useState<boolean>(true); // State to track if there are more data to fetch

    useEffect(() => {
        setIsFetching(true);
        setData([]);
        setHasMoreData(true);

        const fetchData = async () => {
            const request: CaseNotesSearchRequest = {
                search: search,
                context: {
                    overrides: {
                        filters: filters,
                        skip: 0
                    }
                }
            };

            const response = await getCaseNotes(request, await getToken({
                template: "Standard"
            }));
            if (response.ok) {
                const newData = await response.json();
                setData(newData);
                setHasMoreData(newData.length > 0);
                setIsFetching(false);
            }
        };

        fetchData();
    }, [filters, search]);

    useEffect(() => {
        if (isFetching) return;

        if (page > 1 && hasMoreData) {
            setIsFetching(true);

            const fetchMoreData = async () => {
                const request: CaseNotesSearchRequest = {
                    search: search,
                    context: {
                        overrides: {
                            filters: filters,
                            skip: (page - 1) * 10
                        }
                    }
                };

                const response = await getCaseNotes(request, await getToken({
                    template: "Standard"
                }));
                if (response.ok) {
                    const moreData = await response.json();
                    if (moreData.length === 0) {
                        setHasMoreData(false);
                    }
                    setData(prevData => [...prevData, ...moreData]);
                    setIsFetching(false);
                }
            };
            fetchMoreData();
        }
    }, [page]);


    useEffect(() => {
        const handleScroll = (event: any) => {
            const { scrollTop, clientHeight, scrollHeight } = event.target;
            if (!isFetching && scrollTop + clientHeight >= scrollHeight - 100) {
                setPage(prevPage => prevPage + 1);
            }
        };

        const container = document.querySelector(`.${styles.container}`);
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [isFetching]);

    return (
        <div className={styles.container}>
            <Filters includeSearch setSearch={setSearch} filters={filters} setFilters={setFilters}
                     includeFilters={["court", "categories"]} />
            <div style={{
                display: "flex",
                flexDirection: isMobile() ? "column" : "row",
                justifyContent: "space-between"
            }}>
                {!isMobile() && <div className={styles.betaCard}>
                    <BetaCard />
                </div>}
                <Stack className={styles.FeedContainer} spacing={2}>
                    {data.map((row: Document) => <Result row={row} />)}
                    {isFetching && Array.from({ length: 10 }).map((_, index) => <SkeletonResult key={index} />)}
                </Stack>
                {!isMobile() && <div style={{ width: "40dvw" }}></div>}
            </div>
        </div>
    );
};

export default Feed;