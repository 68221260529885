const BACKEND_URI = "";

import { CaseNotesSearchRequest, ChatAppRequest } from "./models";

function getHeaders(idToken: string | null): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login, add the id token of the logged in account as the authorization
    if (idToken) {
        headers["Authorization"] = `Bearer ${idToken}`;
    }

    return headers;
}

export async function chatApi(request: ChatAppRequest, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function listConversations(idToken: string | null, page: number): Promise<Response> {
    let resp = await fetch(`${BACKEND_URI}/conversation?page=${page}`, {
        method: "GET",
        headers: getHeaders(idToken)
    });
    return resp;
}

export async function getConversation(id: string, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/conversation/${id}`, {
        method: "GET",
        headers: getHeaders(idToken),
    });
}

export async function getMonthlyMessagesCount(idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/messages/monthly-count`, {
        method: "GET",
        headers: getHeaders(idToken),
    });
}

export async function getUser(idToken: string | null, invite_code?: string | null, country?: string|null): Promise<Response> {
    let url = `${BACKEND_URI}/user`;
    const params = new URLSearchParams();
    if (invite_code) {
        params.append('invite_code', invite_code);
    }
    if (country) {
        params.append('country', country);
    }
    if (params.toString()) {
        url += `?${params.toString()}`;
    }
    console.log(url)
    return await fetch(url, {
        method: "GET",
        headers: getHeaders(idToken),
    });
}
export async function getCaseNotes(request: CaseNotesSearchRequest, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/search/casenotes`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}