import * as React from "react";
import { CircleFlag } from "react-circle-flags";
import { useContext } from "react";
import { CountryContext } from "../../contexts/CountryContext";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Select, { SelectOption } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { Typography } from "@mui/joy";
import { COUNTRIES } from "../../util/constants";


function renderValue(option: SelectOption<string> | null) {
    if (!option) {
        return null;
    }

    return (
        <ListItemDecorator
            sx={{ justifyContent: "center", width: "25", height: "20px", padding: "0px", margin: "0px" }}>
            <CircleFlag countryCode={COUNTRIES.find((o) => o.code === option.value)?.code || "mystery"} width="25" />
        </ListItemDecorator>

    );
}

export const CountrySelector = () => {
    const { setCountryByCode,country } = useContext(CountryContext);

    const handleCountryChange = (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element> | null, value: string | null) => {
        if (value) {
            setCountryByCode(value);
        }
    };

    return (
        <Select
            value={country.code}
            onChange={handleCountryChange}
            renderValue={renderValue}
            sx={{
                padding: "5px",
                maxHeight: "50px",
                overflow:"hidden"
            }}
        >
            {COUNTRIES.map((country, index) => (
                <Option value={country.code} label={country.name} key={country.code} disabled={!country.enabled} sx={{"height":"20px", overflow:"hidden",marginBottom:"3px"}}>
                    <ListItemDecorator>
                        <CircleFlag style={!country.enabled ? { filter: "grayscale(100%)" } : {}}
                                    width={"30px"} countryCode={country.code} />
                    </ListItemDecorator>
                    <Typography level={"body-sm"}>
                        {country.enabled ? country.name : "Coming soon!"}
                    </Typography>
                </Option>
            ))}
        </Select>
    );
};